import moment from 'moment';

/**
 * 取得特定時間之起始日與結束日
 *  0:所有 1:今日 2:昨日 3:過去七日
 * @returns {Object} startDay:起始日(YYYY-MM-dd) endDay:結束日(YYYY-MM-dd)
 */
export function getSpecificDate(type, startKey, endKey) {
  const now = moment();
  let result = {};
  switch (type) {
    case 0:
      result = {};
      break;
    case 1:
      result[startKey] = now.format('YYYY-MM-DD');
      result[endKey] = now.format('YYYY-MM-DD');
      break;
    case 2:
      result[startKey] = moment().subtract(1, 'day').format('YYYY-MM-DD');
      result[endKey] = moment().subtract(1, 'day').format('YYYY-MM-DD');
      break;
    case 3:
      result[startKey] = moment().subtract(7, 'day').format('YYYY-MM-DD');
      result[endKey] = now.format('YYYY-MM-DD');
      break;
    default:
      result = {};
      break;
  }
  return result;
}

