<template lang="pug">
  div
    .row.justify-content-end.align-items-center
      .col-md-2.form-group
        b-form-select(v-model="filter.dateType")
          option(
            v-for="option in filter_option"
            :key="option.value"
            :value="option.value"
          ) {{ option.text }}
      template(v-if="filter.dateType === 4")
        .col-md-2.form-group
          input.form-control(
            v-model="filter.start_date"
            type="date"
            value-type="format"
            :placeholder="$t('start_date')"
          )
        .col-md-2.form-group
          input.form-control(
            v-model="filter.end_date"
            type="date"
            value-type="format"
            :placeholder="$t('end_date')"
          )
      .col-md-2.form-group
        m-select(
          v-model="filter.consultants"
          :options="options.consultants"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="顧問"
          label="label"
          track-by="value"
          multiple
        )
          template(
            slot="selection"
            slot-scope="{ values, search, isOpen }"
          )
            span.multiselect__single(v-if="values.length && !isOpen")
              | {{ values.length }} options selected
      .col-md-2.form-group
        m-select(
          v-model="filter.interestingTopics"
          :options="options.interestingTopics"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="主題"
          label="label"
          track-by="value"
          multiple
        )
          template(
            slot="selection"
            slot-scope="{ values, search, isOpen }"
          )
            span.multiselect__single(v-if="values.length && !isOpen")
              | {{ values.length }} options selected
      .col-md-2
        button.btn.btn-primary.btn-block.mb-3(
          @click="getClassRecord()"
        ) {{ $t("search") }}
    template(v-if="classroomList.length > 0")
      .reg-classRecords
        div(
          v-for="classroomData in classroomList"
          :key="classroomData.class_applications"
        )
          vip-classroom(
            :show_consultant_img="false"
            classroom_type="records"
            :classroom-data="classroomData"
          )
      b-pagination(
        v-model="currentPage"
        :total-rows="classroomPaination.last_page"
        :per-page="1"
        align="center"
      )
    template(v-else)
      .reg-no-record {{ $t('class_records.no_wuwow_record') }}
</template>

<script type="text/javascript">
import multiselectSelect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import classroomConstant from '@/constants/classroom';
import vipClassroom from '@/components/class/vipClassroom/index.vue';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { getSpecificDate } from '@/utils/filter';
import lioshutanApi from '@/api';
import api, { Platform } from '@lioshutan/api-package';
import {
  mapState,
} from 'vuex';
import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';
import sweetalert from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  name: 'ClassRecords',

  components: {
    vipClassroom,
    datePicker,
    mSelect: multiselectSelect,
  },
  mixins: [WuwowLoadingMixin],

  data() {
    return {
      test: [],
      options: {
        consultants: [],
        interestingTopics: [],
      },
      filter: {
        dateType: 3,
        start_date: '',
        end_date: '',
        consultants: [],
        interestingTopics: [],
      },

      filter_option: [
        { value: 1, text: this.$t('today') },
        { value: 2, text: this.$t('yesterday') },
        { value: 3, text: this.$t('the_past_seven_day') },
        { value: 4, text: this.$t('custom') + this.$t('date') },
        { value: 0, text: this.$t('view_all') },
      ],

      currentPage: 1,

      classroomList: {},

      classroomPaination: {},

      dateRange: {},
    };
  },

  computed: {
    ...mapState({
      token: (state) => state['auth']['token'],
    }),
  },

  watch: {
    currentPage(next, current) {
      if (next !== this.classroomPaination.current_page) {
        this.getClassRecord();
      }
    },

    'filter.dateType'(val, old){
      this.dateRange = getSpecificDate(val, 'classTimeStart', 'classTimeEnd');
    },
  },

  async created() {
    const tag = this.startLoading();
    // 預設日期範圍為今日
    this.dateRange = getSpecificDate(this.filter.dateType, 'classTimeStart', 'classTimeEnd');
    this.getClassRecord();

    try {
      const interestingTopicsResponse = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .vip(this.token)
        .getHaveBeenStudyingInterestingTopic({
          platform: Platform.WUWOW_JUNIOR,
        });

      const consultantsResponse = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .vip(this.token)
        .getWhichTeachersDidTeachingTheStudent({
          platform: Platform.WUWOW_JUNIOR,
        });

      this.options.interestingTopics = interestingTopicsResponse.data.map((data) => ({
        label: data.title,
        value: data.id,
      }));

      this.options.consultants = consultantsResponse.data.map((data) => ({
        label: data.englishName,
        value: data.id,
      }));
      this.endLoading(tag);
    } catch (error) {
      this.endLoading(tag);
      await sweetalert('錯誤', '請通知客服人員：<br>03d59e2e-a116-4f55-be12-3bea33743e8e', 'error');
    }
  },

  methods: {
    async getClassRecord(){
      const tag = this.startLoading();
      // 自訂日期直接設定起訖
      if (this.filter.dateType === 4){
        this.setDateRange();
      }
      const params = {
        hbConsultantIds: this.filter.consultants.map(data => data.value),
        interestedTopicIds: this.filter.interestingTopics.map(data => data.value),
        searchType: classroomConstant.FILTER_STATUS.RECORD,
        page: this.currentPage,
        platform: Platform.WUWOW_JUNIOR,
        ...this.dateRange,
      };
      try {
        const res = await lioshutanApi.classroom.getClassroom(params);
        this.classroomList = res.data;
        this.classroomPaination = res.meta.pagination;
        this.endLoading(tag);
      } catch (error) {
        this.endLoading(tag);
        await sweetalert('錯誤', '請通知客服人員：<br>f4c1d707-8770-4a58-bf8d-7b0574661fa1', 'error');
      }
    },

    setDateRange(){
      this.dateRange.classTimeStart = this.filter.start_date;
      this.dateRange.classTimeEnd = this.filter.end_date;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/class/classRecords";
</style>
<style lang="scss" scoped>
.custom-select {
  min-height: 43px;
}
</style>

